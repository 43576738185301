import React from "react"
import PropTypes from "prop-types"

import { Image } from "cloudinary-react"
var cldData = require("../../config.json")
import { toTitleCase } from "../utils/utils"

const ImageMeta = props => {
  var cldImages = cldData.images.resources
  var alt = null
  var caption = null

  if (!props.publicId) {
    return ""
  }
  for (let i = 0; i < cldImages.length; i++) {
    if (props.publicId === cldImages[i].public_id && cldImages[i].context) {
      alt = cldImages[i].context.custom.alt
      break
    } else {
      const altSentence = props.publicId
        .split("/")[2] //split cloudinary prefex (NUVO/V3/)
        .replace(/[_-]/g, " ")
      alt = toTitleCase(altSentence)
    }
  }

  return (
    <Image alt={alt} responsive {...props} quality="99">
      {props.children}
    </Image>
  )
}

ImageMeta.propTypes = {
  publicId: PropTypes.string,
  children: PropTypes.element
}

export default ImageMeta
